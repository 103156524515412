main.privacy-view
{
	display: flex;
	flex-direction: column;
	padding: 50px 100px;
	box-sizing: border-box;

	header
	{
		display: flex;
		flex-direction: column;

		h1
		{
			margin: 0;
		}

		a
		{
			width: min-content;
			min-width: auto;
		}
	}

	// Styles for markdown document content
	article
	{
		font-family: var(--fontFamilyBase);
		line-height: var(--lineHeightBase300);
		font-size: var(--fontSizeBase300);
		font-weight: var(--fontWeightRegular);
		user-select: text;

		h2
		{
			line-height: var(--lineHeightBase500);
			font-size: var(--fontSizeBase500);
			font-weight: var(--fontWeightSemibold);
		}

		a, a:visited
		{
			text-decoration: none;
			color: var(--colorBrandForegroundLink);

			&:hover
			{
				color: var(--colorBrandForegroundLinkHover);
			}

			&:active
			{
				color: var(--colorBrandForegroundLinkPressed);
			}
		}
	}

	@media screen and (max-width: 600px)
	{
		padding: 20px;
	}
}
