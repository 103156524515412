main.about-view
{
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	height: 100vh;
	justify-content: space-between;
	align-items: flex-start;
	padding: 50px 100px;
	box-sizing: border-box;

	img
	{
		max-width: 200px;
		margin: 20px;
	}

	section
	{
		display: flex;
		flex-direction: column;
		flex-grow: 1;
		gap: 20px;

		header
		{
			display: flex;
			flex-direction: column;

			h1
			{
				margin: 0px;
			}

			a
			{
				width: min-content;
				min-width: auto;
			}
		}

		.blocks
		{
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			flex-wrap: wrap;
			gap: 20px;

			> div
			{
				display: flex;
				flex-direction: column;
				max-width: 300px;

				.fui-Button
				{
					width: min-content;
					margin-top: 10px;
				}
			}
		}

		.socials
		{
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;
			gap: 10px;

			.social-icon:hover
			{
				filter: drop-shadow(var(--shadow8));
				filter: brightness(.8);
			}
		}
	}

	@media screen and (max-width: 600px)
	{
		padding: 20px;
		justify-content: center;
	}
}
