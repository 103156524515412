body
{
	user-select: none;
	margin: 0;

	.slideUpIn
	{
		animation-name: slideUpInAnim;
		animation-timing-function: var(--curveEasyEaseMax);
		animation-duration: .5s;
	}

	.slideRightIn
	{
		animation-name: slideRightInAnim;
		animation-timing-function: var(--curveEasyEaseMax);
		animation-duration: .5s;
	}

	.slideLeftIn
	{
		animation-name: slideLeftInAnim;
		animation-timing-function: var(--curveEasyEaseMax);
		animation-duration: .5s;
	}

	.fadeIn
	{
		animation-name: fadeInAnim;
		animation-timing-function: var(--curveEasyEaseMax);
		animation-duration: .5s;
		animation-fill-mode: forwards;
		opacity: 0;
	}

	.scaleUpIn
	{
		animation-name: scaleUpInAnim;
		animation-timing-function: var(--curveEasyEaseMax);
		animation-duration: .5s;
	}

	main.center
	{
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		min-height: 100vh;

		section
		{
			display: flex;
			flex-direction: column;
			align-items: center;
			max-width: 300px;
		}
	}

	#about-btn
	{
		position: fixed;
		bottom: 0;
		right: 0;
	}

	.test-debug
	{
		position: fixed;
		top: 0;
		left: 0;
		margin: 5px;

		span
		{
			user-select: all;
		}
	}
}

// Overrides for default FluentUI styles
a.fui-Button
{
	text-decoration: none;
}

.fui-Spinner__Track
{
	stroke: none !important;
}

// Buy me a coffee button style
.fui-Button.bmc
{
	background-color: var(--colorPaletteDarkOrangeBorder2) !important;

	&:hover
	{
		background-color: var(--colorPaletteDarkOrangeForeground1) !important;
	}

	&:active
	{
		background-color: var(--colorPaletteDarkOrangeBackground2) !important;
	}
}

@keyframes slideUpInAnim
{
	from
	{
		transform: translateY(10%);
		opacity: 0;
	}
	to
	{
		transform: translateY(0);
		opacity: 1;
	}
}

@keyframes slideRightInAnim
{
	from
	{
		transform: translateX(20%);
		opacity: 0;
	}
	to
	{
		transform: translateX(0);
		opacity: 1;
	}
}

@keyframes slideLeftInAnim
{
	from
	{
		transform: translateX(-20%);
		opacity: 0;
	}
	to
	{
		transform: translateX(0);
		opacity: 1;
	}
}

@keyframes scaleUpInAnim
{
	from
	{
		transform: scale(.5);
		opacity: 0;
	}
	to
	{
		transform: scale(1);
		opacity: 1;
	}
}

@keyframes fadeInAnim
{
	from
	{
		opacity: 0;
	}
	to
	{
		opacity: 1;
	}
}
