main.start-view
{
	section
	{
		gap: 10px;

		header
		{
			display: flex;
			flex-direction: column;
			align-items: center;
		}

		.qr-placeholder
		{
			position: relative;
			width: 300px;
			height: 300px;

			.spinner-block
			{
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				display: flex;
				align-items: center;
				justify-content: center;
				backdrop-filter: blur(6px);
			}
		}

		footer
		{
			margin-top: 10px;
			display: grid;
			gap: 10px;
			align-items: center;
			grid-template-columns: 32px auto;
		}
	}

	// Alert action gap fix
	.fui-Alert button
	{
		margin-left: 8px;
	}
}
