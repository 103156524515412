.download-dialog
{
	max-width: 310px !important;

	.dialog-body
	{
		display: flex;
		flex-direction: column;

		.qrcode
		{
			margin: 10px 0px;
		}
	}
}
