main.download-view
{
	display: flex;
	flex-direction: column;
	gap: 50px;
	padding: 20px;
	box-sizing: border-box;

	section
	{
		flex-direction: row !important;
		flex-wrap: wrap;
		gap: 50px;
		box-sizing: border-box;
		justify-content: center;
		max-width: unset !important;

		a
		{
			width: 250px;
			height: 250px;

			div
			{
				display: grid;
				grid-template-rows: 1fr auto;
				gap: 20px;
				padding: 20px;
				box-sizing: border-box;
				align-items: center;

				img
				{
					width: 100%;
					height: 100%;
					padding: 10px;
				}

				span
				{
					text-align: center;
				}
			}
		}
	}

	@media screen and (max-width: 600px)
	{
		gap: 20px;

		section
		{
			gap: 20px;

			a
			{
				width: 200px;
				height: 200px;
			}
		}
	}
}
