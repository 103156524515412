main.result-view
{
	gap: 15px;
	overflow: hidden;

	header
	{
		display: flex;
		flex-direction: column;
		align-items: center;

		&.error
		{
			color: var(--colorPaletteRedForeground1);
		}

		&.success
		{
			color: var(--colorPaletteGreenForeground1);
		}

		h1
		{
			margin: 0;
		}

		svg
		{
			width: 32px;
			height: 32px;
		}
	}

	> div
	{
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 15px;
	}

	section
	{
		width: 100%;
		gap: 10px;

		.fui-Input
		{
			width: 100%;
		}
	}
}

.callout-content
{
	display: flex;
	flex-direction: column;
	gap: 10px;
	max-width: 300px;
	translate: 0px -10px;
}
